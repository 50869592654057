/*
 * Copyright 2019 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const PRIVATE = Symbol('@@webxr-polyfill/XRRenderState');

export const XRRenderStateInit = Object.freeze({
  depthNear: 0.1,
  depthFar: 1000.0,
  inlineVerticalFieldOfView: null,
  baseLayer: null
});

export default class XRRenderState {
  /**
   * @param {Object?} stateInit
   */
  constructor(stateInit = {}) {
    const config = Object.assign({}, XRRenderStateInit, stateInit);
    this[PRIVATE] = { config };
  }

  /**
   * @return {number}
   */
  get depthNear() { return this[PRIVATE].config.depthNear; }

  /**
   * @return {number}
   */
  get depthFar() { return this[PRIVATE].config.depthFar; }

  /**
   * @return {number?}
   */
  get inlineVerticalFieldOfView() { return this[PRIVATE].config.inlineVerticalFieldOfView; }

  /**
   * @return {XRWebGLLayer}
   */
  get baseLayer() { return this[PRIVATE].config.baseLayer; }
}
