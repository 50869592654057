/*
 * Copyright 2017 Google Inc. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Token for storing whether or not a WebGLRenderingContext has
 * a polyfill for `xrCompatible`/`makeXRCompatible()`
 */
export const POLYFILLED_XR_COMPATIBLE = Symbol('@@webxr-polyfill/polyfilled-xr-compatible');

/**
 * Token for storing the XR compatible boolean set on a WebGLRenderingContext
 * via `gl.makeXRCompatible()` or via creation
 * parameters like `canvas.getContext('webgl', { xrCompatible })`
 */
export const XR_COMPATIBLE = Symbol('@@webxr-polyfill/xr-compatible');
